<template>
  <span>
    {{ $t("xy") }}: {{ row.meanObservedSpreadPercentageXY | percentage(2) }}
    <br />
    {{ $t("z") }}: {{ row.meanObservedSpreadPercentageZ | percentage(2) }}
  </span>
</template>

<script>
import BaseColumn from "./BaseColumn";

export default {
  extends: BaseColumn,
};
</script>
