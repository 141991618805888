export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
