<template>
  <div class="card filter-settings mb-5">
    <b-collapse class="panel" animation="slide">
      <template #trigger="props">
        <div class="panel-heading">
          <h2>{{ $t("filter") }} / {{ $t("settings") }}</h2>
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </div>
      </template>
      <div class="card-content">
        <b-tabs v-model="tab">
          <b-tab-item value="filter" :label="$t('filter')">
            <div v-if="config.filterDate" class="date-filter">
              <b-field :label="$t('period')" horizontal label-for="from">
                <CustomDatePicker
                  v-model="resultsFilter.filter.from"
                  :max="resultsFilter.filter.till"
                  :horizontal="false"
                  :required="false"
                  :placeholder="$t('from')"
                  :max-today="true"
                  class="mr-1"
                ></CustomDatePicker>
                <CustomDatePicker
                  v-model="resultsFilter.filter.till"
                  :min="resultsFilter.filter.from"
                  :horizontal="false"
                  :required="false"
                  :placeholder="$t('till')"
                  :max-today="true"
                ></CustomDatePicker>
              </b-field>
            </div>

            <template v-for="column in config.columns">
              <div v-if="column.field === 'product.name'" :key="column.field">
                <Multiselect
                  field="product"
                  :options="products"
                  :results-filter="resultsFilter"
                  :label="$tc('product')"
                ></Multiselect>
              </div>

              <div v-if="column.field === 'device'" :key="column.field">
                <Multiselect
                  field="device"
                  :options="devices"
                  :results-filter="resultsFilter"
                  :label="$tc('device')"
                ></Multiselect>
              </div>

              <div v-if="column.field === 'accessory'" :key="column.field">
                <Multiselect
                  field="accessory"
                  :options="accessories"
                  :results-filter="resultsFilter"
                  :label="$tc('accessory')"
                ></Multiselect>
              </div>

              <div v-if="column.field === 'client.name'" :key="column.field">
                <Multiselect
                  field="client"
                  :options="clients"
                  :results-filter="resultsFilter"
                  :label="$t('cloud_customer')"
                ></Multiselect>
              </div>

              <div v-if="column.field === 'contract'" :key="column.field">
                <Multiselect
                  field="contract"
                  :options="contracts"
                  :results-filter="resultsFilter"
                  :label="$tc('contract')"
                ></Multiselect>
              </div>

              <div v-if="column.field === 'user.name'" :key="column.field">
                <Multiselect
                  field="user"
                  :options="users"
                  :results-filter="resultsFilter"
                  :label="$tc('user')"
                ></Multiselect>
              </div>

              <div v-if="column.field === 'pipeOwner'" :key="column.field">
                <Multiselect
                  field="pipeOwner"
                  :options="pipeOwners"
                  :results-filter="resultsFilter"
                  :label="$t('pipeline_owner')"
                ></Multiselect>
              </div>

              <div v-if="column.field === 'pipeType'" :key="column.field">
                <Multiselect
                  field="pipeType"
                  :options="pipeTypes"
                  :results-filter="resultsFilter"
                  :label="$t('pipe_type')"
                ></Multiselect>
              </div>

              <div v-if="column.field === 'expired'" :key="column.field">
                <b-field horizontal label-for="expired">
                  <b-checkbox v-model="resultsFilter.filter.expired">
                    {{ $t("include_expired") }}
                  </b-checkbox>
                </b-field>
              </div>
            </template>

            <div class="ml-auto">
              <b-button
                icon-left="autorenew"
                class="is-pulled-right"
                @click="resetFilter"
              >
                {{ $t("reset") }}
              </b-button>
            </div>
          </b-tab-item>

          <b-tab-item value="settings" :label="$t('settings')">
            <div>
              <b-field
                :label="$tc('currency', 1)"
                horizontal
                label-for="currency"
              >
                <b-select id="currency" v-model="settings.currency">
                  <option v-for="c in currencies" :key="c" :value="c">
                    {{ $t(c) }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div>
              <CustomInput
                v-model="settings.conversionRate"
                :label="$t('conversion_rate')"
                :type="'number'"
              ></CustomInput>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomDatePicker from "../../../components/forms/CustomDatePicker";
import CustomInput from "../../../components/forms/CustomInput";
import Multiselect from "./Multiselect";

export default {
  components: { CustomInput, CustomDatePicker, Multiselect },
  props: {
    config: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    resultsFilter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tab: "filter",
      currencies: ["EUR", "USD"],
      products: this.resultsFilter.getDeepValues("product.name"),
      devices: this.resultsFilter.getValues("device"),
      accessories: this.resultsFilter.getValues("accessory"),
      clients: this.resultsFilter.getDeepValues("client.name"),
      contracts: this.resultsFilter.getValues("contract"),
      users: this.resultsFilter.getDeepValues("user.name", {
        client: this.resultsFilter.filter.client,
      }),
      pipeOwners: this.resultsFilter.getValues("pipeOwner"),
      pipeTypes: this.resultsFilter.getValues("pipeType"),
    };
  },
  computed: {
    ...mapGetters(["userHasRoleSuperAdmin"]),
  },
  watch: {
    "resultsFilter.filter.client": {
      handler(value) {
        this.users = this.resultsFilter.getDeepValues("user.name", {
          client: value,
        });

        // Clear selected users that don't belong to the selected clients
        this.resultsFilter.filter.user.forEach((u, i) => {
          if (!this.users.includes(u)) {
            this.resultsFilter.filter.user.splice(i, 1);
          }
        });
      },
    },
  },
  methods: {
    resetFilter() {
      Object.keys(this.resultsFilter.filter).forEach((k) => {
        if (Array.isArray(this.resultsFilter.filter[k])) {
          this.resultsFilter.filter[k] = [];
        } else {
          this.resultsFilter.filter[k] = null;
        }
      });
    },
  },
};
</script>
