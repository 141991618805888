<template>
  <b-field :label="label" :label-for="`multiselect${_uid}`" horizontal>
    <b-dropdown v-model="resultsFilter.filter[field]" expanded multiple>
      <template #trigger="{ active }">
        <b-button
          :id="`multiselect${_uid}`"
          :label="selectionLabel"
          :icon-right="active ? 'menu-up' : 'menu-down'"
          expanded
        />
      </template>
      <b-dropdown-item @click="resultsFilter.filter[field] = []">
        {{ $t("all") }}
      </b-dropdown-item>
      <b-dropdown-item v-for="o in options" :key="o" :value="o">
        {{ o }}
      </b-dropdown-item>
    </b-dropdown>
  </b-field>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    resultsFilter: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectionLabel() {
      if (this.resultsFilter.filter[this.field].length === 0) {
        return this.$t("all");
      }
      return this.resultsFilter.filter[this.field].map((c) => c).join(", ");
    },
  },
};
</script>
