export default class BaseConfig {
  columns = [];

  fields() {
    return this.columns.map((c) => c.field);
  }

  hasField(key) {
    return this.fields().includes(key);
  }
}
