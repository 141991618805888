<template>
  <span>
    {{ $t("ab") }}: {{ row.totalNumberOfRunsAB }}<br />
    {{ $t("ba") }}: {{ row.totalNumberOfRunsBA }}<br />
    <small>{{ $t("total") }}: {{ row.totalNumberOfRuns }}</small>
  </span>
</template>

<script>
import BaseColumn from "./BaseColumn";

export default {
  extends: BaseColumn,
};
</script>
