<template>
  <span>{{ row[field] | distance(row.systemOfMeasurement.name) }}</span>
</template>

<script>
import BaseColumn from "./BaseColumn";

export default {
  extends: BaseColumn,
};
</script>
