import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { get, sortBy, uniq } from "lodash-es";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default class ResultsFilter {
  results;
  enableDateFilter;
  checkedResults;
  filter = {
    contract: [],
    from: dayjs().subtract(3, "days").format("YYYY-MM-DD"),
    till: dayjs().format("YYYY-MM-DD"),
    accessory: [],
    device: [],
    pipeOwner: [],
    pipeType: [],
    product: [],
    client: [],
    user: [],
  };

  constructor(results, enableDateFilter = true) {
    this.results = results;
    this.checkedResults = results;

    this.enableDateFilter = enableDateFilter;
  }

  updateResults(results) {
    this.results = results;
  }

  getFilteredResults() {
    let res = [...this.results];

    if (this.filter.user && this.filter.user.length > 0) {
      res = res.filter((r) => this.filter.user.includes(r.user.name));
    }

    if (this.filter.client && this.filter.client.length > 0) {
      res = res.filter((r) => this.filter.client.includes(r.client.name));
    }

    if (this.filter.contract && this.filter.contract.length > 0) {
      res = res.filter((r) => this.filter.contract.includes(r.contract));
    }

    if (this.filter.product && this.filter.product.length > 0) {
      res = res.filter((r) => this.filter.product.includes(r.product.name));
    }

    if (this.filter.accessory && this.filter.accessory.length > 0) {
      res = res.filter((r) => this.filter.accessory.includes(r.accessory));
    }

    if (this.filter.device && this.filter.device.length > 0) {
      res = res.filter((r) => this.filter.device.includes(r.device));
    }

    if (!this.filter.expired) {
      res = res.filter((r) => !r.expired);
    }

    if (this.filter.pipeOwner && this.filter.pipeOwner.length > 0) {
      res = res.filter((r) => this.filter.pipeOwner.includes(r.pipeOwner));
    }

    if (this.filter.pipeType && this.filter.pipeType.length > 0) {
      res = res.filter((r) => this.filter.pipeType.includes(r.pipeType));
    }

    if (!this.enableDateFilter) {
      return res;
    }

    if (this.filter.from) {
      res = res.filter((r) =>
        dayjs(r.date).isSameOrAfter(this.filter.from, "day")
      );
    }

    if (this.filter.till) {
      res = res.filter((r) =>
        dayjs(r.date).isSameOrBefore(this.filter.till, "day")
      );
    }

    return res;
  }

  getCheckedResults() {
    return this.checkedResults;
  }

  updateCheckedResults(checkedResults) {
    this.checkedResults = checkedResults;
  }

  getCheckedFilteredResultsInOrder() {
    const results = this.getFilteredResults().filter((r) =>
      this.checkedResults.find((cr) => cr.id === r.id)
    );
    return sortBy(results, ["date"]);
  }

  search(search) {
    let filtered = this.getFilteredResults();

    if (search) {
      const term = search.toLowerCase();
      filtered = filtered.filter((r) => {
        return (
          r.contract?.includes(term) ||
          r.device?.toLowerCase().includes(term) ||
          r.pipeOwner?.toLowerCase().includes(term) ||
          r.client?.name?.toLowerCase().includes(term) ||
          r.fieldOperators?.toLowerCase().includes(term)
        );
      });
    }

    return filtered;
  }

  getValues(key) {
    // Filter out results that don't have a value for the provided key
    const withoutEmpty = this.results.filter((r) => r[key]);

    // Extract values for provided key
    const values = withoutEmpty.map((r) => r[key]);

    // Filter out duplicate values
    const uniqueValues = uniq(values);

    // Return values in alphabetical order
    return sortBy(uniqueValues);
  }

  getDeepValues(path, filter = null) {
    // Extract values at provided path
    let values = this.results.map((r) => get(r, path));

    let filterKey = null;
    let filterValue = null;
    if (filter) {
      filterKey = Object.keys(filter)[0];
      filterValue = Object.values(filter)[0];
    }

    if (
      filter &&
      filterKey === "client" &&
      filterValue &&
      filterValue.length > 0
    ) {
      values = this.results
        .filter((v) => filterValue.includes(v.client.name))
        .map((r) => get(r, path));
    }

    // Filter out duplicate values
    const uniqueValues = uniq(values);

    // Return values in alphabetical order
    return sortBy(uniqueValues);
  }
}
