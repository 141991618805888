<template>
  <div class="card results">
    <b-collapse class="panel" animation="slide">
      <template #trigger="props">
        <div class="panel-heading">
          <h2>{{ $t("table") }}</h2>
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </div>
      </template>
      <div class="card-content">
        <CustomInput
          v-model="debouncedSearch"
          :type="'search'"
          :placeholder="$t('search')"
          :horizontal="false"
          :icon="'magnify'"
          :required="false"
          class="mt-3"
        ></CustomInput>

        <b-table
          ref="table"
          :data="filteredResults"
          :checkable="config.checkable"
          :checked-rows.sync="checkedResults"
          default-sort="date"
          default-sort-direction="desc"
        >
          <template #empty>
            {{ loading ? $t("loading") : $t("no_matching_results") }}
          </template>

          <b-table-column
            v-for="c in config.columns"
            :key="`${c.component}${c.field}`"
            :label="c.label"
            :field="c.field"
            :numeric="c.numeric"
            :visible="c.visible"
            sortable
          >
            <template v-slot="props">
              <component
                :is="c.component"
                :key="`${c.component}${c.field}${key}`"
                :field="c.field"
                :row="props.row"
                :settings="settings"
              ></component>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import { mapGetters } from "vuex";
import CustomInput from "../../../components/forms/CustomInput";
import BooleanColumn from "./columns/BooleanColumn";
import DateColumn from "./columns/DateColumn";
import DistanceColumn from "./columns/DistanceColumn";
import MeanSpreadColumn from "./columns/MeanSpreadColumn";
import MoneyColumn from "./columns/MoneyColumn";
import MoneyConvertedColumn from "./columns/MoneyConvertedColumn";
import NumberColumn from "./columns/NumberColumn";
import PercentageColumn from "./columns/PercentageColumn";
import RunsColumn from "./columns/RunsColumn";
import SpreadColumn from "./columns/SpreadColumn";
import StringColumn from "./columns/StringColumn";

export default {
  components: {
    BooleanColumn,
    CustomInput,
    DateColumn,
    DistanceColumn,
    MeanSpreadColumn,
    MoneyColumn,
    MoneyConvertedColumn,
    NumberColumn,
    PercentageColumn,
    RunsColumn,
    SpreadColumn,
    StringColumn,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    resultsFilter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      key: 1,
      search: null,
      checkedRows: [],
    };
  },
  computed: {
    ...mapGetters(["userHasRoleSuperAdmin"]),
    debouncedSearch: {
      get() {
        return this.search;
      },
      set: debounce(function (value) {
        this.search = value;
      }, 200),
    },
    filteredResults() {
      return this.resultsFilter.search(this.search);
    },
    checkedResults: {
      get() {
        return this.resultsFilter.getCheckedResults();
      },
      set(value) {
        this.resultsFilter.updateCheckedResults(value);
      },
    },
  },
  watch: {
    "resultsFilter.results"() {
      // Force component update by changing the key,
      // required for devices report dynamic sums and means
      this.key++;
    },
  },
};
</script>
