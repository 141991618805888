<template>
  <span>{{ value ? $t("yes") : $t("no") }}</span>
</template>

<script>
import { get } from "lodash-es";
import BaseColumn from "./BaseColumn";

export default {
  extends: BaseColumn,
  computed: {
    value() {
      return get(this.row, this.field);
    },
  },
};
</script>
